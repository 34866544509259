jQuery(document).ready(function ($) {
  $(".create__color").slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
		infinite: true,
		variableWidth: true
  });

  $(".modal-open-link").on("click", function () {
    $(".modal.open .input-error").removeClass("input-error");
    $(".modal.open").removeClass("open");
    $(".modal-" + $(this).attr("data-modal")).addClass("open");
  });

  $(".create__color").on("click", ".slick-slide", function () {
    var slideIndex = $(this).data("slick-index");
    var totalSlides = $(".create__color").slick("getSlick").slideCount;
    var currentIndex = slideIndex % totalSlides;
    if (currentIndex < 0) {
        currentIndex += totalSlides;
    }
    $(".create__color").slick("slickGoTo", currentIndex);
    $(".create__item").removeClass("active");
    $("#create-item-" + slideIndex).addClass("active");
  });

  $('.create__color').on('beforeChange', function(event, slick, currentSlide, nextSlide) {   
    var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;
    $(".create__loading").css("width", calc + "%");
  });

  $(".flytesters__flytesters").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 400,
    arrows: false,
    dots: false,
    variableWidth: true
  });


  $(".flytesters__flytesters").on("click", ".slick-slide", function () {
    var slideIndex = $(this).data("slick-index");
    var totalSlides = $(".flytesters__flytesters").slick("getSlick").slideCount;
    var currentIndex = slideIndex % totalSlides;
    if (currentIndex < 0) {
        currentIndex += totalSlides;
    }
    $(".flytesters__flytesters").slick("slickGoTo", currentIndex);
  });

  $('.flytesters__flytesters').on('beforeChange', function(event, slick, currentSlide, nextSlide) {   
    var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;
    $(".flytesters__loading").css("width", calc + "%");
  });

  const parent = document.querySelector('.motion-effect-parent');
  const image = document.querySelector('.motion-effect-image');
  
  if (parent && image) {
    const applyMotionEffect = (e) => {
      const rect = parent.getBoundingClientRect();
      const x = e.clientX - rect.left - rect.width / 2;
      const y = e.clientY - rect.top - rect.height / 2;
      const sensitivity = -18;
      image.style.transform = `translate(calc(-50% + ${x / sensitivity}px), calc(-50% + ${y / sensitivity}px))`;
    };
  
    const resetMotionEffect = () => {
      image.style.transform = 'translate(-50%, -50%)';
    };
  
    const handleMouseMove = (e) => {
      if (window.innerWidth > 1024) {
        applyMotionEffect(e);
      }
    };
  
    const handleMouseLeave = () => {
      if (window.innerWidth > 1024) {
        resetMotionEffect();
      }
    };
  
    parent.addEventListener('mousemove', handleMouseMove);
    parent.addEventListener('mouseleave', handleMouseLeave);
  }  

  $(".btn-next").on("click", function () {
    $(".step-box.active").removeClass("active").addClass("completed");
    $(".step-box").eq(1).addClass("active");
    $(".custom-products").removeClass("visible");
    $(".selected-wrap").addClass("visible");
  }); // Attachment file

  var filesAdded = 1;
  var textAdded = 1;
  var fileList = [];
  $(".selected_container").on("change", ".input-file", function (e) {
    var files = this.files;
    var fileNameLength = 17;
    for (var i = 0; i < files.length; i++) {
      var fileNameStr = files.item(i).name;
      var substr = fileNameStr.lastIndexOf(".");
      var newString = fileNameStr.substring(0, fileNameLength);
      var endStr = fileNameStr.substring(substr + 1);
      var resultString = void 0;

      if (fileNameStr.substring(0, substr).length > fileNameLength + 2) {
        resultString = newString + "..." + endStr;
      } else {
        resultString = fileNameStr;
      }

      var fileBloc = $("<span/>", {
        "class": "file-block"
      });
      var fileName = $("<span/>", {
        "class": "name",
        "data-text": files.item(i).name,
        "data-file_count": i,
        text: resultString
      });
      fileBloc.append(fileName).append("<span class=\"file-delete\"><svg width=\"14\" height=\"16\" viewBox=\"0 0 14 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n          <path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.66659 2.08203C5.51188 2.08203 5.3635 2.14349 5.25411 2.25289C5.14471 2.36228 5.08325 2.51066 5.08325 2.66536V3.24872H8.91659V2.66536C8.91659 2.51066 8.85513 2.36228 8.74573 2.25289C8.63634 2.14349 8.48796 2.08203 8.33325 2.08203H5.66659ZM10.4166 3.24872V2.66536C10.4166 2.11283 10.1971 1.58293 9.80639 1.19223C9.41569 0.801525 8.88579 0.582031 8.33325 0.582031H5.66659C5.11405 0.582031 4.58415 0.801525 4.19345 1.19223C3.80275 1.58293 3.58325 2.11283 3.58325 2.66536V3.24872H2.33333H2.33325H1C0.585786 3.24872 0.25 3.5845 0.25 3.99872C0.25 4.41293 0.585786 4.74872 1 4.74872H1.58325V13.3321C1.58325 13.8846 1.80274 14.4145 2.19345 14.8052C2.58415 15.1959 3.11405 15.4154 3.66659 15.4154H10.3333C10.8858 15.4154 11.4157 15.1959 11.8064 14.8052C12.1971 14.4145 12.4166 13.8846 12.4166 13.3321V4.74872H13C13.4142 4.74872 13.75 4.41293 13.75 3.99872C13.75 3.5845 13.4142 3.24872 13 3.24872H11.6666H10.4166ZM10.9166 4.74872H3.08325V13.3321C3.08325 13.4868 3.14471 13.6351 3.25411 13.7445C3.3635 13.8539 3.51188 13.9154 3.66659 13.9154H10.3333C10.488 13.9154 10.6363 13.8539 10.7457 13.7445C10.8551 13.6351 10.9166 13.4868 10.9166 13.3321V4.74872ZM6.41675 7.33209C6.41675 6.91788 6.08096 6.58209 5.66675 6.58209C5.25253 6.58209 4.91675 6.91788 4.91675 7.33209V11.3321C4.91675 11.7463 5.25253 12.0821 5.66675 12.0821C6.08096 12.0821 6.41675 11.7463 6.41675 11.3321V7.33209ZM8.33325 6.58209C8.74747 6.58209 9.08325 6.91788 9.08325 7.33209V11.3321C9.08325 11.7463 8.74747 12.0821 8.33325 12.0821C7.91904 12.0821 7.58325 11.7463 7.58325 11.3321V7.33209C7.58325 6.91788 7.91904 6.58209 8.33325 6.58209Z\" fill=\"black\"/>\n          </svg></span>");
      $(this).closest(".input-wrap").find(".files-names").append(fileBloc);
    }

    for (var _i = 0; _i < files.length; _i++) {
      fileList.push(files[_i]);
    }

    filesAdded = 0;
    $(".input-file").each(function () {
      if ($(this).prop('files').length === 0) {
        filesAdded++;
      }
    });

    if (textAdded === 0 && filesAdded === 0) {
      $(".btn-checkout").removeClass("disabled");
    } else {
      $(".btn-checkout").addClass("disabled");
    }
  });
  $(".selected_container").on("click", ".file-delete", function (event) {
    var name = $(this).closest(".file-block").find("span.name").attr("data-text");
    var file_count = $(this).closest(".file-block").find("span.name").attr("data-file_count");
    var data_id = $(this).closest('.upload-field').attr('data-id');
    var value_ob = JSON.parse($('.uploade_hidden-' + data_id).val());
    var new_value_ob = {};
    fileList = fileList.filter(function (item, index) {
      if (item.name != name) {
        console.log(index);
        new_value_ob[index] = value_ob[index]; // delete value_ob[inыdex];
      }

      return item.name !== name;
    });
    filesAdded = 0;
    $(".input-file").each(function () {
      if ($(this).val() === '') {
        filesAdded++;
      }
    });
    $('.uploade_hidden-' + data_id).val(JSON.stringify(new_value_ob));
    $(this).parent().remove();

    if (textAdded === 0 && filesAdded === 0 || fileList.length === 0) {
      console.log(fileList);
      $(".btn-checkout").removeClass("disabled");
    } else {
      $(".btn-checkout").addClass("disabled");
    }

    if ($(this).closest(".file-block").length === 0) {
      $(".btn-checkout").addClass("disabled");
    }
  });
  $(".btn-step-back").on("click", function () {
    $(".step-box.active").removeClass("active");
    $(".step-box.completed").removeClass("completed");
    $(".step-box").eq(0).addClass("active");
    $(".custom-products").addClass("visible");
    $(".selected-wrap").removeClass("visible");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  });
  $(".selected_container").on("input", ".input-textarea", function () {
    textAdded = 0;
    $(".input-textarea").each(function () {
      if ($(this).val().trim().length === 0) {
        textAdded++;
      }
    });

    if (textAdded === 0 && filesAdded === 0) {
      $(".btn-checkout").removeClass("disabled");
    } else {
      $(".btn-checkout").addClass("disabled");
    }
  });

  $('body').on('click', '.coupon_header', function () {
    $('.coupon').toggleClass('open');
  });
  $('body').on('click', '.product-dropdown_arrow-button', function () {
    $(this).closest('.cart_item').toggleClass('open');
  });
  $('body').on('click', '.cart-info_empty', function () {
    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      type: 'POST',
      data: {
        action: 'empty-all-cart'
      }
    }).done(function (response) {
      if (response.result == null) {
        window.location.reload();
      }
    });
  });
  $(document).ready(function () {
    if (!$('.woocommerce-cart .woocommerce-notices-wrapper').find('.button').length) {
      var coupon_notice = $('.woocommerce-cart .woocommerce-notices-wrapper').text();
      $('.coupon-error').text(coupon_notice);
      if ($('.coupon-error').text()) {
        $('.coupon-error').show();
        setTimeout(function () {
          $('.coupon-error').hide();
        }, 3000);
      } else {
        $('.coupon-error').hide();
      }
    }
  });

  document.addEventListener(
    "wpcf7mailsent",
    function (event) {
      if (event.detail.contactFormId == '770') {
        document.querySelector(".modal-success").classList.add("open");
      }
    },
    false
  );

  $(".modal_close-btn, .js-close-modal").on("click", function (e) {
    $(this).closest(".modal").find(".input-error").removeClass("input-error");
    $(this).closest(".modal").find("form").trigger('reset');
    $(this).closest(".modal").removeClass("open");
  });

  $('body').on('click', '.coupon_header', function () {
    $('.coupon').toggleClass('open');
  });
  $('body').on('click', '.product-dropdown_arrow-button', function () {
    $(this).closest('.cart_item').toggleClass('open');
  });
  $(document).ready(function () {
    if (!$('.woocommerce-cart .woocommerce-notices-wrapper').find('.button').length) {
      var coupon_notice = $('.woocommerce-cart .woocommerce-notices-wrapper').text();
      $('.coupon-error').text(coupon_notice);

      if ($('.coupon-error').text()) {
        $('.coupon-error').show();
        setTimeout(function () {
          $('.coupon-error').hide();
        }, 3000);
      } else {
        $('.coupon-error').hide();
      }
    }
  });
});